import React, { useEffect, Suspense } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { DotLoader } from 'react-spinners';
import Chatbot from './Footer/Chatbot';
import RefundPolicy from './TermsAndCondition/RefundPolicy';
import About from './About/About';
import Privacy from './Privacy/Privacy';

// Lazy load the components
const CloudCourseMain = React.lazy(() => import('./components/CloudCourseMain'));
const LandingPage = React.lazy(() => import('./LandingPageComponents/LandingPage'));
const AllFooter = React.lazy(() => import('./Footer/AllFooter'));
const AllHeader = React.lazy(() => import('./Header/AllHeader'));
const StickyFooter = React.lazy(() => import('./Footer/StickyFooter'));
const BlogsPage = React.lazy(() => import('./Blogs/BlogsPage'));
const TermsAndCondition = React.lazy(() => import('./TermsAndCondition/TermsAndCondition'));
const ThankyouPage = React.lazy(() => import('./ThankyouComponent/ThankyouPage'));

const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('config', 'G-N1CE0V9ZXD', {
        page_path: location.pathname,
      });
      window.gtag('config', 'AW-16687929732', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <div className="container">
      <Helmet>
        {/* Google Analytics (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1CE0V9ZXD"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N1CE0V9ZXD', {
              page_path: window.location.pathname,
            });
            gtag('config', 'AW-16687929732', {
              page_path: window.location.pathname,
            });
          `}
        </script>
      </Helmet>
      <Suspense fallback={<div style={{margin: "20% auto 50% auto", display: "table"}}><DotLoader color="#8f128e" loading/></div>}>
        <AllHeader />
        <StickyFooter />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/devops-training-online" element={<CloudCourseMain />} />
          <Route path="/blog" element={<BlogsPage />} />
          <Route path="/terms" element={<TermsAndCondition />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/thankyou" element={<ThankyouPage />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
        <Chatbot />
        <AllFooter />
      </Suspense>
    </div>
  );
}

const AppWithRouter = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWithRouter;
