import React, { useEffect } from 'react';

const Chatbot = () => {
  useEffect(() => {
    const tawkScript = document.createElement('script');
    tawkScript.type = 'text/javascript';
    tawkScript.async = true;
    tawkScript.src = 'https://code.jivosite.com/widget/VgijZIbJ6O';
    tawkScript.charset = 'UTF-8';
    tawkScript.setAttribute('crossorigin', '*');

    document.head.appendChild(tawkScript);

    return () => {
      // Clean up the script when the component unmounts
      document.head.removeChild(tawkScript);
    };
  }, []);

  return (
    <noscript>
      Powered by <a href="https://paperlive.in/" target="_blank" rel="noopener noreferrer">PaperLive</a>
    </noscript>
  );
};

export default Chatbot;
