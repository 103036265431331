import React from "react";
import { Breadcrumbs, Link, Typography, useMediaQuery, useTheme } from "@mui/material";

export default function RefundPolicy(props) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <React.Fragment>
            <div role="presentation" style={{ width: "90%", margin: "1% auto 0 auto" }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Typography color="text.primary">Refund Policy</Typography>
                </Breadcrumbs>
            </div>
            <div style={{ width: "80%", margin: "3% auto 0 auto" }}>
                <div>
                    <span className={isSmallScreen ? 'only-heading-sm' : 'only-heading'}>Refund </span>
                    <span className={isSmallScreen ? 'gradient-heading-sm' : 'gradient-heading'}>Policy</span>
                </div>
                <ul style={{ margin: "2%" }}>
                    <li>All refund requests must be submitted in writing to <Link>support@paperlive.in</Link></li>
                    <li>Refunds will be processed within 30 business days of receiving the request.</li>
                    <li>The refund policy terms are subject to change without prior notice. The terms in effect at the time of your cancellation or request will apply.</li>
                    <li>To cancel your enrollment in a training program, please notify us in writing within 5 days of registration or before attending the first class, whichever comes first. If you do so, you will be eligible for a full refund.</li>
                    <li>If you do not cancel within this period, no refund will be provided.</li>
                </ul>

            </div>
        </React.Fragment>
    );
}