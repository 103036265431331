import { Breadcrumbs, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

const About = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <React.Fragment>
        <div role="presentation" style={{ width: "90%", margin: "1% auto 0 auto" }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">About Us</Typography>
            </Breadcrumbs>
        </div>
        <div style={{ width: "80%", margin: "3% auto 0 auto" }}>
            <div>
                <span className={isSmallScreen ? 'only-heading-sm' : 'only-heading'}>About </span>
                <span className={isSmallScreen ? 'gradient-heading-sm' : 'gradient-heading'}>Us</span>
            </div>
            <div class="container">
    {/* <h1>Welcome to PaperLive</h1> */}
    <br></br>
    <p>There are many platforms out there offering DevOps and Cloud courses. But then, there’s us – PaperLive. We may not be the largest, but we are among the most dedicated. We are passionate about creating life-changing learning experiences, and that’s what sets us apart. Our focus isn’t just on teaching; it’s on ensuring you are job-ready, equipped with real-world skills.</p>
    <br></br>
    <h3>What makes us different?</h3>
    <p>Our commitment. When you enroll in a PaperLive course, we commit to your success. We’re not just here to give you information; we’re here to ensure you understand, apply, and succeed. Our team of expert instructors, 24/7 support, and interactive lessons are designed to keep you engaged and make sure you reach the finish line. No excuses, no shortcuts – we will help you master the skills you need.</p>

    <p>At PaperLive, we believe in the power of transformation. We’re not just about teaching DevOps or Cloud concepts; we’re about helping you achieve your career dreams. Whether it’s constant guidance, hands-on projects, or personalized learning paths, we are relentlessly focused on one thing – your success.</p>
    <br></br>
    <h3>Our Vision</h3>
    <p>To become the leading platform for specialized DevOps and Cloud education, empowering professionals to excel in the fast-evolving tech industry.</p>
    <br></br>
    <h3>Our Mission</h3>
    <p>Our mission is to provide an industry-aligned learning experience that goes beyond theory. With expert-led training and hands-on projects, we aim to bridge the skills gap and equip our learners with the tools needed to thrive in the tech world. Backed by our team of dedicated educators and 24/7 support, we are here to guide you every step of the way, ensuring your success.</p>

    <p>At PaperLive, we are committed to providing education that makes a difference. If you're ready to take the next step in your career, we’re here to make it happen – together.</p>
  </div>

        </div>
    </React.Fragment>
    );
}

export default About;
